<template>
  <nav
    class="navbar navbar-expand-md navbar-dark fixed-top csn-nav csn-navbar-game-play"
    :class="{ 'csn-nav-lower': isLower }"
  >
    <div
      id="navbarCollapse"
      class="collapse csn-nav-collapse csn-nav-game-play-collapse"
    >
      <div
        class="pull-left csn-navbar-game-play-name csn-navbar-game-play-left"
      >
        <a
          @click="goBack"
          class="btn casino-btn casino-btn-default casino-buttons pull-left csn-nav-btn csn-nav-btn-game"
        >
          <span class="casino-icon casino-icon-nav-left"></span>
        </a>
        <h1>{{ gameName }}</h1>
      </div>

      <NavbarLogoButton @click="exitFullScreenMode" />

      <ul class="account-menu login-in csn-navbar-game-play-account-menu">
        <li v-if="rendersLogin">
          <Route
            class="nav-link casino-btn casino-btn-default csn-nav-btn"
            :to="{ name: route.login }"
          >
            {{ t('login') }}
          </Route>
        </li>
        <li v-if="rendersLogin">
          <Route
            class="nav-link casino-btn casino-btn-pink"
            data-title="Register Account"
            :to="{ name: route.registration }"
          >
            {{ t('createAccount') }}
          </Route>
        </li>

        <li>
          <Route
            :to="{ name: route.home }"
            @click.native="exitFullScreenMode"
            class="btn casino-btn casino-btn-default casino-buttons csn-nav-btn csn-nav-btn-game"
          >
            <span class="casino-icon casino-icon-close"></span>
          </Route>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import {
  Module,
  GAME_PLAY_DICTIONARY,
  RouteName,
  NAVBAR__GAME_PANEL,
  GAME_DEMO_DICTIONARY,
  IS_LOGGED,
  PREVIOUS_LOCATION,
} from '@/constants'
import { path, dest, exitFullScreenMode } from '@/helpers'

const GAME_NAME = 'gameName'

export default {
  name: NAVBAR__GAME_PANEL,
  components: {
    NavbarLogoButton: () => import('./NavbarLogoButton'),
    Route: () => import('@/components/Route'),
  },
  props: {
    isGameDemo: Boolean,
    isLower: Boolean,
  },
  computed: {
    ...mapState(Module.GAME_PLAY, [GAME_PLAY_DICTIONARY]),
    ...mapState(Module.GAME_DEMO, [GAME_DEMO_DICTIONARY]),
    ...mapState(Module.LOCATION, [PREVIOUS_LOCATION]),
    ...mapGetters({ isLogged: dest([Module.USER, IS_LOGGED]) }),
    gameSlug() {
      return this.$router.history.current.params.slug
    },
    gameName() {
      const dictionary = this.isGameDemo
        ? GAME_DEMO_DICTIONARY
        : GAME_PLAY_DICTIONARY

      return path([dictionary, this.gameSlug, GAME_NAME], this)
    },
    rendersLogin() {
      return this.isGameDemo && !this.isLogged
    },
    route() {
      return {
        home: RouteName.HOME,
        dashboard: RouteName.DASHBOARD,
        deposit: RouteName.DEPOSIT,
        depositHistory: RouteName.USER_DEPOSIT_HISTORY,
        registration: RouteName.REGISTRATION,
        login: RouteName.LOGIN,
      }
    },
    t() {
      return this.$createComponentTranslator(NAVBAR__GAME_PANEL)
    },
  },
  methods: {
    exitFullScreenMode,
    goBack() {
      this.exitFullScreenMode()
      this.$router.push({ name: this.PREVIOUS_LOCATION.name || RouteName.HOME })
    },
  },
}
</script>
